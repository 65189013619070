import { IResourceComponentsProps } from "@pankod/refine-core";
import {
  List,
  Table,
  TextField,
  useTable,
  getDefaultSortOrder,
  Space,
  EditButton,
} from "@pankod/refine-antd";
import {IProfile} from "types";
import {FC} from "react";

export const ProfileList: FC<IResourceComponentsProps> = () => {
  const { tableProps, sorter } = useTable<IProfile>({});
  return (
    <List>
      <Table {...tableProps} rowKey="id">
        <Table.Column
          dataIndex="email"
          key="email"
          title="Email"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("email", sorter)}
        />
        <Table.Column
          dataIndex="name"
          key="name"
          title="Name"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("name", sorter)}
        />
        <Table.Column
          dataIndex="role"
          key="role"
          title="Role"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("role", sorter)}
        />
        <Table.Column
          dataIndex="bio"
          key="bio"
          title="Bio"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("bio", sorter)}
        />
        <Table.Column<IProfile>
          title="Actions"
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
