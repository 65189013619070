import {FC, useState} from "react";
import { IResourceComponentsProps } from "@pankod/refine-core";
import {
  Create,
  Form,
  Input,
  useForm,
  useSelect,
  Select,
} from "@pankod/refine-antd";
import "react-mde/lib/styles/css/react-mde-all.css";
import { IProduct, IReview } from "types";
import { LOCALES } from "../../config";
import ReactMde from "react-mde";
import ReactMarkdown from "react-markdown";

export const ReviewCreate: FC<IResourceComponentsProps> = () => {
  const [ selectedTab, setSelectedTab ] = useState<"write" | "preview">("write");
  const { formProps, saveButtonProps } = useForm<IReview>();
  const { selectProps: productSelectProps } = useSelect<IProduct>({
    resource: 'products',
    optionLabel: 'name',
    pagination: { pageSize: Number.MAX_SAFE_INTEGER },
  });
  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">

        <Form.Item
          label="Content"
          name="content"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <ReactMde
            selectedTab={selectedTab}
            onTabChange={setSelectedTab}
            generateMarkdownPreview={(markdown) =>
              Promise.resolve(<ReactMarkdown>{markdown}</ReactMarkdown>)
            }
          />
        </Form.Item>

        <Form.Item
          label="Rating (1 ~ 5)"
          name="rating"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input type="number" />
        </Form.Item>

        <Form.Item
          label="Weight (1 ~ 4,294,967,296)"
          name="weight"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input type="number" />
        </Form.Item>

        <Form.Item
          label="Locale"
          name="locale"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Select
            placeholder='Select Locale'
            options={LOCALES.map(locale => ({label: locale, value: locale}))}
          />
        </Form.Item>

        <Form.Item
          label="Product"
          name={["product", "id"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            placeholder='Select Product'
            {...productSelectProps}
          />
        </Form.Item>
      </Form>
    </Create>
  );
};
