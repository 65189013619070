import { IResourceComponentsProps } from "@pankod/refine-core";
import {
  List,
  Table,
  TextField,
  useTable,
  getDefaultSortOrder,
  DateField,
  Space,
  EditButton,
  DeleteButton,
  ShowButton, Tag,
} from "@pankod/refine-antd";
import {ICategoryFeature, IFeature} from "types";
import {FC} from "react";

export const FeatureList: FC<IResourceComponentsProps> = () => {
  const { tableProps, sorter } = useTable<IFeature>({
    initialSorter: [
      {
        field: "createdAt",
        order: "desc",
      },
    ],
  });
  return (
    <List>
      <Table {...tableProps} rowKey="id">
        <Table.Column
          dataIndex="name"
          key="name"
          title="Name"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("name", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="label"
          key="label"
          title="Label"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("label", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="locale"
          key="locale"
          title="Locale"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("locale", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="categories"
          key="categories"
          title="Categories"
          render={(value: ICategoryFeature[]) => (
            <TextField value={value?.map(({name, required}) => <Tag>{name}{required ? ` (Required)` : ''}</Tag>)} />
          )}
        />
        <Table.Column
          dataIndex="createdAt"
          key="createdAt"
          title="Created At"
          render={(value) => <DateField value={value} format="LLL" />}
          defaultSortOrder={getDefaultSortOrder("createdAt", sorter)}
          sorter
        />

        <Table.Column<IFeature>
          title="Actions"
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <ShowButton hideText size="small" recordItemId={record.id} />
              <DeleteButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
