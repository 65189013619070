import {FormInstance} from "antd";
import {useApiUrl, useCustom} from "@pankod/refine-core";
import {useEffect, useState} from "react";
import {IProduct} from "../types";

const MAX_PROS: number = 100;

export const prosToObject = (pros: string[]) => pros.reduce((result, current, index) => ({...result, [`pros.${index}`]: current}), {});
export const toPros = (form: FormInstance) => {
  const pros: string[] = [];
  const values: any = form.getFieldsValue();
  for (let i = 0; i < MAX_PROS; i++) {
    const value: string | undefined = values[`pros.${i}`];
    if (value === undefined) break;
    pros.push(value);
  }
  return pros;
};

export const useProductLocale = (id: string, form: FormInstance) => {
  const apiUrl = useApiUrl();
  const [ locale, setLocale] = useState<string>();
  const [ version, setVersion ] = useState<number>();
  const { data, isSuccess } = useCustom<IProduct>({
    url: `${apiUrl}/products/${id}`,
    method: "get",
    queryOptions: { enabled: !!locale },
    config: { query: { locale } },
  });
  useEffect(() => {
    if (!data || locale !== data.data?.locale || !isSuccess) return;
    const { finalUrl, heading, shortDescription, description: _description, pros, features, } = data.data;
    const description: string = _description ?? '';
    form.setFieldsValue( { finalUrl, heading, shortDescription, description, pros, ...prosToObject(pros), features });
    setVersion(new Date().getTime());
  }, [locale, data?.data?.locale, isSuccess]);
  return { setLocale, version };
}
