import {useState} from "react";
import {Form} from "@pankod/refine-antd";
import ReactMde from "react-mde";
import ReactMarkdown from "react-markdown";

type Props = {
  label: string;
  name: string;
  rules: Record<string, any>[]
};

export const MdeFormItem = ({label, name, rules}: Props) => {
  const [selectedTab, setSelectedTab] = useState<"write" | "preview">("write");
  return (
    <Form.Item label={label} name={name} rules={rules}>
      <ReactMde
        selectedTab={selectedTab}
        onTabChange={setSelectedTab}
        generateMarkdownPreview={(markdown) =>
          Promise.resolve(<ReactMarkdown>{markdown}</ReactMarkdown>)
        }
      />
    </Form.Item>
  );
}