import { FC } from "react";
import { IResourceComponentsProps, useMany } from "@pankod/refine-core";
import {
  List,
  Table,
  TextField,
  useTable,
  getDefaultSortOrder,
  DateField,
  Space,
  EditButton,
  DeleteButton,
  ShowButton,
} from "@pankod/refine-antd";
import { IProduct, IReview } from "types";

export const ReviewList: FC<IResourceComponentsProps> = () => {
  const { tableProps, sorter } = useTable<IReview>({
    initialSorter: [
      {
        field: "createdAt",
        order: "desc",
      },
    ],
  });

  const productIds = tableProps?.dataSource?.map((item) => item.product.id) ?? [];
  const { data: productsData, isLoading: isLoadingProduct } = useMany<IProduct>({
    resource: "products",
    ids: productIds,
    queryOptions: { enabled: productIds.length > 0, },
  });

  return (
    <List>
      <Table {...tableProps} rowKey="id">
        <Table.Column
          dataIndex="content"
          key="content"
          title="Content"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("content", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="rating"
          key="rating"
          title="Rating"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("rating", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="weight"
          key="weight"
          title="Weight"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("weight", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="locale"
          key="locale"
          title="Locale"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("locale", sorter)}
        />
        <Table.Column
          dataIndex="createdAt"
          key="createdAt"
          title="Created At"
          render={(value) => <DateField value={value} format="LLL" />}
          defaultSortOrder={getDefaultSortOrder("createdAt", sorter)}
          sorter
        />
        <Table.Column
          dataIndex={["product", "id"]}
          title="Product"
          render={(value) => {
            if (isLoadingProduct) return <TextField value="Loading..." />;
            return (
              <TextField value={productsData?.data.find((item) => item.id === value)?.name} />
            );
          }}
        />
        <Table.Column<IReview>
          title="Actions"
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <ShowButton hideText size="small" recordItemId={record.id} />
              <DeleteButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
