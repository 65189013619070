import {Form, getValueFromEvent, Upload} from "@pankod/refine-antd";
import {useApiUrl} from "@pankod/refine-core";
import axios from "axios";

type Props = { name: string; label: string; folder: string; };
export const FileUpload = ({name, label, folder}: Props) => {
  const apiUrl = useApiUrl();
  return (
    <Form.Item label={label}>
      <Form.Item
        name={name}
        valuePropName="fileList"
        getValueFromEvent={getValueFromEvent}
        noStyle
      >
        <Upload.Dragger
          name="file"
          action={`${apiUrl}/upload?type=${folder}`}
          headers={{ 'Authorization': axios.defaults.headers.common.Authorization.toString() }}
          listType="picture"
          multiple={true}
          maxCount={10}
        >
          <p className="ant-upload-text">
            Drag & drop a file in this area
          </p>
        </Upload.Dragger>
      </Form.Item>
    </Form.Item>
  )
}
