import { IResourceComponentsProps, useShow } from "@pankod/refine-core";
import { Show, Typography } from "@pankod/refine-antd";
import { ITrackingTemplate } from "types";
import { FC } from "react";

const { Title, Text } = Typography;

export const TrackingTemplateShow: FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow<ITrackingTemplate>();
  const { data, isLoading } = queryResult;
  const record = data?.data;
  return (
    <Show isLoading={isLoading}>
      <Title level={5}>Name</Title>
      <Text>{record?.name}</Text>
  
      <Title level={5}>Placeholder</Title>
      <Text>{record?.placeholder}</Text>
    </Show>
  );
};
