import React, { FC } from "react";
import { IResourceComponentsProps, useMany } from "@pankod/refine-core";
import {
  List,
  Table,
  TextField,
  useTable,
  getDefaultSortOrder,
  DateField,
  Space,
  EditButton,
  DeleteButton,
  ShowButton,
  ImageField,
} from "@pankod/refine-antd";
import * as R from 'ramda';
import {ICategory, IImage, IProduct} from "types";
import {Search} from "../../components/Search";

export interface IOptions {
  label: string | React.ReactNode;
  options: IOptionGroup[];
}

export interface IOptionGroup {
  value: string;
  label: string | React.ReactNode;
}

export const ProductList: FC<IResourceComponentsProps> = () => {
  const { tableProps, sorter } = useTable<IProduct>({
    initialSorter: [
      {
        field: "createdAt",
        order: "desc",
      },
    ],
  });

  const categoryIds: string[] = tableProps?.dataSource?.map((item) => item.categories).flat() ?? [];
  const { data: categoriesData, isLoading: isLoadingCategories } = useMany<ICategory>({
    resource: "categories",
    ids: categoryIds,
    queryOptions: {
      enabled: categoryIds.length > 0,
    },
  });
  return (
    <List>
      <Search resource='products' displayField='name' />
      <Table {...tableProps} rowKey="id">
        <Table.Column
          dataIndex="name"
          key="name"
          title="Name"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("name", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="alias"
          key="alias"
          title="Alias"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("alias", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="mpn"
          key="mpn"
          title="MPN"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("mpn", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="sku"
          key="sku"
          title="SKU"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("sku", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="cpc"
          key="cpc"
          title="CPC"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("cpc", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="phone"
          key="phone"
          title="Phone Nymber"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("phone", sorter)}
          sorter
        />
        <Table.Column
          dataIndex={["categories"]}
          title="Categories"
          render={(values) => {
            if (isLoadingCategories) return <TextField value="Loading..." />;
            return (
              <TextField value={values.map((id: string) => categoriesData?.data.find(R.propEq('id', id))?.name).join(', ')} />
            );
          }}
        />
        <Table.Column
          dataIndex={["brand"]}
          title="Brand"
          render={(value) => {
            return (
              <TextField value={value?.name} />
            );
          }}
        />
        <Table.Column
          dataIndex={["carouselImages"]}
          title="Carousel image"
          render={(value: IImage[]) => {
            const image: IImage | undefined = R.head(value || []);
            return image?.url ? (
              <ImageField key={image?.name} value={image.url} title={image?.name} width={70} />
            ) : null;
          }}
        />
        <Table.Column
          dataIndex="createdAt"
          key="createdAt"
          title="Created At"
          render={(value) => <DateField value={value} format="LLL" />}
          defaultSortOrder={getDefaultSortOrder("createdAt", sorter)}
          sorter
        />
        <Table.Column<IProduct>
          title="Actions"
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} href={`/products/edit/${record.id}`} />
              <ShowButton hideText size="small" recordItemId={record.id} />
              <DeleteButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
