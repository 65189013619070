import { IResourceComponentsProps, useShow } from "@pankod/refine-core";
import {Show, Tag, Typography} from "@pankod/refine-antd";
import { IFeature } from "types";
import {FC} from "react";

const { Title, Text } = Typography;

export const FeatureShow: FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow<IFeature>();
  const { data, isLoading } = queryResult;
  const record = data?.data;
  return (
    <Show isLoading={isLoading}>
      <Title level={5}>Name</Title>
      <Text>{record?.name}</Text>

      <Title level={5}>Label</Title>
      <Text>{record?.label}</Text>

      <Title level={5}>Locale</Title>
      <Text>{record?.locale}</Text>

      <Title level={5}>Categories</Title>
      {record?.categories?.map(({name, required}) => <Tag>{name}{required ? ` (Required)` : ''}</Tag>)}
    </Show>
  );
};
