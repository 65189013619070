import { IResourceComponentsProps, useShow } from "@pankod/refine-core";
import {Show, Space, Typography, ImageField,} from "@pankod/refine-antd";
import { IBrand } from "types";
import {FC} from "react";

const { Title, Text } = Typography;

export const BrandShow: FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow<IBrand>();
  const { data, isLoading } = queryResult;
  const record = data?.data;
  return (
    <Show isLoading={isLoading}>
      <Title level={5}>Name</Title>
      <Text>{record?.name}</Text>

      <Title level={5}>Logo</Title>
      <Space wrap>
        {record?.logo?.map((logo) => (
          <ImageField
            key={logo.name}
            value={logo.url}
            title={logo.name}
            width={200}
          />
        ))}
      </Space>
    </Show>
  );
};
