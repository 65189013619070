import { IResourceComponentsProps, useShow } from "@pankod/refine-core";
import { ImageField, Show, Space, Typography } from "@pankod/refine-antd";
import { ICategory } from "types";
import { FC } from "react";

const { Title, Text } = Typography;

export const CategoryShow: FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow<ICategory>();
  const { data, isLoading } = queryResult;
  const record = data?.data;
  return (
    <Show isLoading={isLoading}>
      <Title level={5}>Name</Title>
      <Text>{record?.name}</Text>

      <Title level={5}>Alias</Title>
      <Text>{record?.alias}</Text>
  
      <Title level={5}>Title</Title>
      <Text>{record?.title}</Text>
  
      <Title level={5}>Description</Title>
      <Text>{record?.description}</Text>
  
      <Title level={5}>Hero image</Title>
      <Space wrap>
        {record?.heroImage?.map((heroImage) => (
          <ImageField
            key={heroImage.name}
            value={heroImage.url}
            title={heroImage.name}
            width={200}
          />
        ))}
      </Space>
      <Title level={5}>Hero Feature Icon</Title>
      <Text>{String(record?.icon)}</Text>
      <Title level={5}>Feature Gallery Image</Title>
      <Space wrap>
        {record?.categoryImage?.map((categoryImage) => (
          <ImageField
            key={categoryImage.name}
            value={categoryImage.url}
            title={categoryImage.name}
            width={200}
          />
        ))}
      </Space>
      <Title level={5}>Widget Configuration</Title>
      <Text>{JSON.stringify(record?.configuration)}</Text>
      <Title level={5}>Widget Enabled</Title>
      <Text>{String(record?.widget_enabled)}</Text>
      <Title level={5}>Widget Type</Title>
      <Text>{record?.widget_type}</Text>
    </Show>
  );
};
