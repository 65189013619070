import {FC, useState} from 'react';
import {IResourceComponentsProps} from "@pankod/refine-core";
import {
  Select,
  Edit,
  Form,
  Input,
  useSelect,
  useForm,
} from "@pankod/refine-antd";
import "react-mde/lib/styles/css/react-mde-all.css";
import {ICategory, IContentUnit} from "../../types";
import {FileUpload} from "../../components";
import {ContentUnitStatus, LOCALES} from "../../config";
import ReactMde from "react-mde";
import ReactMarkdown from "react-markdown";

export const ContentUnitEdit: FC<IResourceComponentsProps> = () => {
  const [ selectedTab, setSelectedTab ] = useState<"write" | "preview">("write");
  const { formProps, saveButtonProps } = useForm<IContentUnit>();
  const { selectProps: categorySelectProps } = useSelect<ICategory>({
    resource: 'categories',
    optionLabel: 'name',
    pagination: { pageSize: Number.MAX_SAFE_INTEGER },
  });
  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Slug"
          name="slug"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Title"
          name="title"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Content"
          name="content"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <ReactMde
            selectedTab={selectedTab}
            onTabChange={setSelectedTab}
            generateMarkdownPreview={(markdown) =>
              Promise.resolve(<ReactMarkdown>{markdown}</ReactMarkdown>)
            }
          />
        </Form.Item>

        <Form.Item
          label="Breadcrumb"
          name="breadcrumb"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Locale"
          name="locale"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            placeholder='Select Locale'
            options={LOCALES.map(locale => ({label: locale, value: locale}))}
          />
        </Form.Item>

        <Form.Item
          label="Status"
          name="status"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            placeholder='Select Status'
            options={
              [ContentUnitStatus.DRAFT, ContentUnitStatus.PUBLISHED].map(status => ({label: status, value: status}))
            }
          />
        </Form.Item>

        <Form.Item
          label="Category"
          name={["category", "id"]}
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Select
            placeholder='Select Category'
            {...categorySelectProps}
            options={[ { label: '-- No Category --', value: null }, ...(categorySelectProps.options ?? []), ]}
          />
        </Form.Item>

        <FileUpload name="images" label="Images" folder="content-units" />
      </Form>
    </Edit>
  );
};
