import { Refine } from "@pankod/refine-core";
import { notificationProvider, Layout } from "@pankod/refine-antd";
import routerProvider from "@pankod/refine-react-router-v6";
import "@pankod/refine-antd/dist/styles.min.css";
import dataProvider from "@pankod/refine-simple-rest";
import { useAuthenticator, Authenticator } from '@aws-amplify/ui-react';
import axios from "axios";
import {
  BrandCreate,
  BrandEdit,
  BrandList, BrandShow,
  CategoryCreate,
  CategoryEdit,
  CategoryList,
  CategoryShow,
  ProductCreate,
  ProductEdit,
  ProductList,
  ProductShow,
  ProfileEdit,
  ProfileList
} from "./pages";
import {createAuthProvider, extractClaim, adapter, model} from "./config";
import {newEnforcer} from "casbin.js";
import {ReviewCreate, ReviewEdit, ReviewList, ReviewShow} from "./pages/reviews";
import {TOP6_CMS_API_ENDPOINT} from "./config/constants";
import {ContentUnitCreate, ContentUnitEdit, ContentUnitList, ContentUnitShow} from "./pages/content-units";
import {FeatureCreate, FeatureEdit, FeatureList, FeatureShow} from "./pages/features";
import {
  TrackingTemplateCreate,
  TrackingTemplateEdit,
  TrackingTemplateList,
  TrackingTemplateShow
} from "./pages/tracking-templates";

const Login = () => <Authenticator loginMechanisms={['email']} />;

function App() {
  const { route, user, signOut } = useAuthenticator((context) => [context.route]);
  const authenticated = route === 'authenticated';
  const { role } = extractClaim(user);
  const idToken = user?.getSignInUserSession()?.getIdToken()?.getJwtToken();
  if (idToken) axios.defaults.headers.common = { Authorization: `Bearer ${idToken}` };
  axios.interceptors.response.use(
    res => res, (error) => Promise.reject({ ...error, message: error.response?.data?.message, statusCode: error.response?.status }),
  );
  return authenticated ? (
    <Refine
      routerProvider={routerProvider}
      notificationProvider={notificationProvider}
      Layout={Layout}
      authProvider={createAuthProvider({signOut, authenticated, user})}
      accessControlProvider={{
        can: async ({ resource, action, params }) => {
          const enforcer = await newEnforcer(model, adapter);
          const can = await enforcer.enforce(role, resource, action);
          if (action === "delete" || action === "edit" || action === "show") {
            const can = await enforcer.enforce(role, `${resource}/${params?.id}`, action);
            return Promise.resolve({ can });
          }

          return Promise.resolve({ can });
        },
      }}
      dataProvider={dataProvider(TOP6_CMS_API_ENDPOINT, axios as any)}
      resources={[
        {
          name: "brands",
          list: BrandList,
          show: BrandShow,
          edit: BrandEdit,
          create: BrandCreate,
        },
        {
          name: "tracking-templates",
          list: TrackingTemplateList,
          show: TrackingTemplateShow,
          edit: TrackingTemplateEdit,
          create: TrackingTemplateCreate,
        },
        {
          name: "categories",
          list: CategoryList,
          show: CategoryShow,
          edit: CategoryEdit,
          create: CategoryCreate,
        },
        {
          name: "features",
          list: FeatureList,
          show: FeatureShow,
          edit: FeatureEdit,
          create: FeatureCreate,
        },
        {
          name: "products",
          list: ProductList,
          show: ProductShow,
          edit: ProductEdit,
          create: ProductCreate,
        },
        {
          name: "reviews",
          list: ReviewList,
          show: ReviewShow,
          edit: ReviewEdit,
          create: ReviewCreate,
        },
        {
          name: "content-units",
          list: ContentUnitList,
          show: ContentUnitShow,
          edit: ContentUnitEdit,
          create: ContentUnitCreate,
        },
        {
          name: "profiles",
          list: ProfileList,
          edit: ProfileEdit,
        },
      ]}
    />
  ) :
  <Login />;
}

export default App;