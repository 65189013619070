import {Card, Col, Form, Input, Row} from "@pankod/refine-antd";
import {FormInstance} from "antd";
import {Control, useFieldArray} from "@pankod/refine-react-hook-form";
import {prosToObject, toPros} from "../config";

type Props = {
  form: FormInstance,
  control: Control,
}

export const Pros = ({form, control}: Props) => {
  const { append: appendPros, remove: removePros } = useFieldArray({
    control,
    name: "pros",
  });
  
  const addPros = (identifier: number) => {
    const pros: string[] = [...toPros(form), ''];
    form.setFieldsValue({ pros, ...prosToObject(pros), });
    appendPros(identifier);
  };
  const deletePros = (index: number) => {
    let pros: string[] = toPros(form);
    pros = pros.slice(0, index).concat(pros.slice(index + 1));
    form.setFieldsValue({ pros, ...prosToObject(pros), });
    removePros(index);
  };
  
  return (
    <>
      <Form.Item name="pros" style={{display: 'none'}} rules={[ { required: false, }, ]}>
        <Input />
      </Form.Item>
      {
        <Card style={{ marginTop: "16px" }} size="small" type="inner" title={(
          <div>
            Pros
            &nbsp;
            <button type='button' onClick={() => addPros(new Date().getTime())}>Add</button>
          </div>
        )}>
          {
            form.getFieldValue('pros')?.map((record: any, index: number) => {
              return (
                <div key={index}>
                  <Row gutter={[8, 8]}>
                    <Col span={20}>
                      <Form.Item
                        name={`pros.${index}`}
                        rules={[ { required: true, }, ]}
                      >
                        <Input defaultValue={record.value} />
                      </Form.Item>
                    </Col>
                    <Col>
                      <button type='button' onClick={() => deletePros(index)}>Remove</button>
                    </Col>
                  </Row>
                </div>
              );
            })
          }
        </Card>
      }
    </>
  );
};