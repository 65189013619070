import React from "react";
import {
  AntdLayout,
  AutoComplete,
  Input,
  Icons,
  Typography,
} from "@pankod/refine-antd";
import { useState, useEffect } from "react";
import { useList } from "@pankod/refine-core";
import routerProvider from "@pankod/refine-react-router-v6";
import { IOptions } from "../pages";
const { Link } = routerProvider;
const { Text } = Typography;
const { SearchOutlined } = Icons;

type Props = {
  resource: string;
  displayField: string;
};

export const Search = ({resource, displayField}: Props) => {
  const [value, setValue] = useState<string>("");
  const [options, setOptions] = useState<IOptions[]>([]);
  const { refetch } = useList({
    resource,
    config: {
      filters: [{ field: "q", operator: "contains", value }],
    },
    queryOptions: {
      enabled: false,
      onSuccess: (data) => {
        const optionGroups = data.data.map((item) =>
          renderItem(item.id as string, item[displayField], resource),
        );
        if (optionGroups.length > 0) {
          setOptions((prevOptions) => [
            ...prevOptions,
            {
              label: resource.toUpperCase(),
              options: optionGroups,
            },
          ]);
        }
      },
    },
  });
  
  useEffect(() => {
    setOptions([]);
    (async () => refetch())();
  }, [value]);
  
  return (
    <AntdLayout.Header
      style={{
        padding: "0px 24px",
        backgroundColor: "#FFF",
      }}
    >
      <AutoComplete
        style={{ width: "100%", maxWidth: "550px" }}
        options={options}
        filterOption={false}
        onSearch={(value: string) => setValue(value)}
      >
        <Input
          size="large"
          placeholder="Search"
          suffix={<SearchOutlined />}
        />
      </AutoComplete>
    </AntdLayout.Header>
  )
}

const renderItem = (id: string, name: string, resource: string) => {
  return {
    value: name,
    label: (
      <Link to={`/${resource}/show/${id}`}>
        <div><Text>{name}</Text></div>
      </Link>
    ),
  };
};