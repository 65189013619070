import {FC, useState} from "react";
import { IResourceComponentsProps, useApiUrl } from "@pankod/refine-core";
import {
  Create,
  Form,
  getValueFromEvent,
  Input,
  Select,
  Switch,
  Upload,
  useForm,
} from "@pankod/refine-antd";
import "react-mde/lib/styles/css/react-mde-all.css";
import { ICategory } from "types";
import axios from "axios";
import { MdeFormItem } from "../../components";

export const CategoryCreate: FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm<ICategory>();
  const apiUrl = useApiUrl();
  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">

        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Alias"
          name="alias"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item label="Title" name="title" rules={[ { required: true } ]}>
          <Input />
        </Form.Item>

        <MdeFormItem label="Description" name="description" rules={[ { required: true } ]} />

        <Form.Item label="Hero image">
          <Form.Item
            name="heroImage"
            valuePropName="fileList"
            getValueFromEvent={getValueFromEvent}
            noStyle
          >
            <Upload.Dragger
              name="file"
              action={`${apiUrl}/upload?type=hero-images`}
              headers={{ 'Authorization': axios.defaults.headers.common.Authorization.toString() }}
              listType="picture"
              multiple={false}
              maxCount={1}
            >
              <p className="ant-upload-text">
                Drag & drop a file in this area
              </p>
            </Upload.Dragger>
          </Form.Item>
        </Form.Item>
        {/* hero_enabled & categories_enabled for home page rendering */}
        <Form.Item label="Hero Feature Icon" name="icon">
          <Input />
        </Form.Item>
        <Form.Item label="Feature Gallery Image">
          <Form.Item
            name="categoryImage"
            valuePropName="fileList"
            getValueFromEvent={getValueFromEvent}
            noStyle
          >
            <Upload.Dragger
              name="file"
              action={`${apiUrl}/upload?type=category-images`}
              headers={{ 'Authorization': axios.defaults.headers.common.Authorization.toString() }}
              listType="picture"
              multiple={false}
              maxCount={1}
            >
              <p className="ant-upload-text">
                Drag & drop a file in this area
              </p>
            </Upload.Dragger>
          </Form.Item>
        </Form.Item>
        {/* fullpage widget */}
        <Form.Item label="Configuration" name="configuration">
          <Input.TextArea />
        </Form.Item>

        <Form.Item label="Widget Enabled" name="widget_enabled" initialValue={false}>
          <Switch />
        </Form.Item>

        <Form.Item label="Widget Type" name="widget_type">
          <Select>
            <Select.Option value="custom_widget">Custom Widget</Select.Option>
            <Select.Option value="mediaalpha">MediaAlpha</Select.Option>
            <Select.Option value="quinstreet">QuinStreet</Select.Option>
            <Select.Option value="transparently">Transparently</Select.Option>
            <Select.Option value="myfinance">MyFinance/BankRate</Select.Option>
            <Select.Option value="best_colleges">Best Colleges</Select.Option>
          </Select>
        </Form.Item>
        {/*  */}

      </Form>
    </Create>
  );
};
