import { FC } from "react";
import { IResourceComponentsProps } from "@pankod/refine-core";
import {
  Create,
  Form,
  Input,
  Select,
  useForm,
} from "@pankod/refine-antd";
import "react-mde/lib/styles/css/react-mde-all.css";

import {IFeature} from "types";
import {Locale, LOCALES} from "../../config";
import {Categories, onSubmit} from "./edit";

export const FeatureCreate: FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, form, onFinish } = useForm<IFeature>();
  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} onFinish={onSubmit(onFinish)}  initialValues={{ locale: Locale.EN_US }} layout="vertical">
        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Label"
          name="label"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Locale"
          name="locale"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            placeholder='Select Locale'
            options={LOCALES.map(locale => ({label: locale, value: locale}))}
          />
        </Form.Item>
        <Categories form={form} />
      </Form>
    </Create>
  );
};
