import {FormInstance} from "antd";
import {Card, Form, Input} from "@pankod/refine-antd";
import {IFeatureValue} from "../types";

type Props = {
  form: FormInstance;
};

export const Features = ({form}: Props) => (
  <Card style={{ marginTop: "16px" }} size="small" type="inner" title="Features">
    {
      form.getFieldValue('features')?.map((feature: IFeatureValue, index: number) => {
        return (
          <div key={feature.id}>
            <Form.Item name={[`features`, index, 'id']} hidden><Input /></Form.Item>
            <Form.Item name={[`features`, index, 'required']} hidden><Input /></Form.Item>
            <Form.Item
              label={feature.label}
              name={[`features`, index, 'value']}
              rules={[ { required: feature.required, }, ]}
            >
              <Input />
            </Form.Item>
          </div>
        )
      })
    }
  </Card>
);