import { FC } from "react";
import {CreateResponse, IResourceComponentsProps, UpdateResponse} from "@pankod/refine-core";
import {
  Create,
  Form,
  Input,
  InputNumber,
  useForm,
  useSelect,
  Select,
  Divider,
  Typography,
  Switch,
} from "@pankod/refine-antd";
import "react-mde/lib/styles/css/react-mde-all.css";
import {IBrand, ICategory, IProduct, ITrackingTemplate} from "types";
import {FileUpload, MdeFormItem, Pros} from "../../components";
import {Locale, LOCALES, toPros, useProductLocale} from "../../config";
import {useForm as useReactHookForm} from "@pankod/refine-react-hook-form";

const { Title } = Typography;

export const ProductCreate: FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, id, form, onFinish } = useForm<IProduct>();
  form.setFieldsValue({ locale: Locale.EN_US });
  const { setLocale } = useProductLocale(id as string, form);
  const { selectProps: categorySelectProps } = useSelect<ICategory>({
    resource: 'categories',
    optionLabel: 'name',
    pagination: { pageSize: Number.MAX_SAFE_INTEGER },
    sort: [ { field: "name", order: "asc", }, ],
  });
  const { selectProps: brandSelectProps } = useSelect<IBrand>({
    resource: 'brands',
    optionLabel: 'name',
    pagination: { pageSize: Number.MAX_SAFE_INTEGER },
    sort: [ { field: "name", order: "asc", }, ],
  });
  const { selectProps: trackingTemplateSelectProps } = useSelect<ITrackingTemplate>({
    resource: 'tracking-templates',
    optionLabel: 'name',
    pagination: { pageSize: Number.MAX_SAFE_INTEGER },
    sort: [ { field: "name", order: "asc", }, ],
  });
  const { control } = useReactHookForm();
  const onSubmit = (onFinish: (values: any) => Promise<void | CreateResponse<IProduct> | UpdateResponse<IProduct>>) => async (payload: any) => {
    const pros: string[] = toPros(form);
    for (let i = 0; i < pros.length; i++) {
      delete payload[`pros.${i}`];
    }
    if (payload.rating !== null) payload.rating = parseFloat(payload.rating);
    // Convert to cents
    if (payload.cpc !== null) payload.cpc = parseInt(payload.cpc) * 100;
    await onFinish({...payload, pros: toPros(form)});
  };
  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps}  onFinish={onSubmit(onFinish)} layout="vertical">
        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Alias"
          name="alias"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="MPN"
          name="mpn"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="SKU"
          name="sku"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Phone Number"
          name="phone"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Track Offline Conversion" name="track_offline_conversion" initialValue={false}>
          <Switch />
        </Form.Item>
        <Form.Item
          label="CPC"
          name="cpc"
          rules={[
            {
              required: false,
              validator: async (rule, value) => {
              if (value < 0) {
                throw new Error('CPC cannot be negative');
              }
            },
            },
          ]}
        >
          <Input type = 'number'/>
        </Form.Item>
        <Form.Item
          label="Rating"
          name="rating"
          rules={[
            {
              required: false,
              validator: async (rule, value) => {
                if (value < 0 || value > 5) {
                  throw new Error('Rating must be between 0 - 5');
                }
              },
            },
          ]}
        >
          <InputNumber type='number' min={1.0} max={5.0} />
        </Form.Item>

        <Form.Item
          label="Categories"
          name={["categories"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            mode='multiple'
            placeholder='Select Categories'
            {...categorySelectProps}
          />
        </Form.Item>

        <Form.Item
          label="Brand"
          name={["brand", "id"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            placeholder='Select Brand'
            {...brandSelectProps}
          />
        </Form.Item>
        <Form.Item label="Tracking template" name={["trackingTemplate", "id"]} rules={[ { required: false, }, ]}>
          <Select placeholder='Select Tracking template' {...trackingTemplateSelectProps} />
        </Form.Item>

        <FileUpload name="carouselImages" label="Carousel image" folder="carousels" />
        <FileUpload name="screenshots" label="Screenshot image" folder="screenshots" />

        <Title level={5}>Localization</Title>
        <Divider />
        <Form.Item
          label="Locale"
          name="locale"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            disabled={true}
            placeholder='Select Locale'
            options={LOCALES.map(locale => ({label: locale, value: locale}))}
            onChange={(value: string) => setLocale(value)}
          />
        </Form.Item>
        <Form.Item
          label="Final URL"
          name="finalUrl"
          rules={[
            {
              required: true,
              max: 255,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Heading"
          name="heading"
          rules={[
            {
              required: true,
              // max: 30,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Short description"
          name="shortDescription"
          rules={[
            {
              required: true,
              // max: 90,
            },
          ]}
        >
          <Input />
        </Form.Item>
  
        <MdeFormItem label="Description" name="description" rules={[ { required: true } ]} />

        <Pros form={form} control={control} />

      </Form>
    </Create>
  );
};
