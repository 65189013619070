import {IResourceComponentsProps, useOne, useShow} from "@pankod/refine-core";
import {Show, Typography, Tag} from "@pankod/refine-antd";
import {IProduct, IReview} from "types";
import {FC} from "react";

const { Title, Text } = Typography;

export const ReviewShow: FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow<IReview>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  const { data: productData } = useOne<IProduct>({
    resource: 'products',
    id: record?.product.id ?? '',
    queryOptions: {
      enabled: !!record?.product.id,
    },
  });

  return (
    <Show isLoading={isLoading}>
      <Title level={5}>Content</Title>
      <Text>{record?.content}</Text>

      <Title level={5}>Rating</Title>
      <Text>
        <Tag>{record?.rating}</Tag>
      </Text>

      <Title level={5}>Weight</Title>
      <Text>
        <Tag>{record?.weight}</Tag>
      </Text>

      <Title level={5}>Locale</Title>
      <Text>
        <Tag>{record?.locale}</Tag>
      </Text>

      <Title level={5}>Product</Title>
      <Text>
        {
          <Tag>{productData?.data.name}</Tag>
        }
      </Text>
    </Show>
  );
};
