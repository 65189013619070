import { FC } from "react";
import {IResourceComponentsProps, useApiUrl} from "@pankod/refine-core";
import {
  Create,
  Form, getValueFromEvent,
  Input, Upload,
  useForm,
} from "@pankod/refine-antd";
import "react-mde/lib/styles/css/react-mde-all.css";

import {IBrand} from "types";
import axios from "axios";

export const BrandCreate: FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm<IBrand>();
  const apiUrl = useApiUrl();
  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Logo">
          <Form.Item
            name="logo"
            valuePropName="fileList"
            getValueFromEvent={getValueFromEvent}
            noStyle
          >
            <Upload.Dragger
              name="file"
              action={`${apiUrl}/upload?type=logos`}
              headers={{ 'Authorization': axios.defaults.headers.common.Authorization.toString() }}
              listType="picture"
              multiple={false}
              maxCount={1}
            >
              <p className="ant-upload-text">
                Drag & drop a file in this area
              </p>
            </Upload.Dragger>
          </Form.Item>
        </Form.Item>
      </Form>
    </Create>
  );
};
