import { newModel, MemoryAdapter } from "casbin.js";

export const model = newModel(`
[request_definition]
r = sub, obj, act

[policy_definition]
p = sub, obj, act

[role_definition]
g = _, _

[policy_effect]
e = some(where (p.eft == allow))

[matchers]
m = g(r.sub, p.sub) && keyMatch(r.obj, p.obj) && regexMatch(r.act, p.act)
`);

export const adapter = new MemoryAdapter(`
p, advertiser, brands, (list)|(create)
p, advertiser, brands/*, (edit)|(show)|(delete)

p, advertiser, tracking-templates, (list)|(create)
p, advertiser, tracking-templates/*, (edit)|(show)|(delete)

p, advertiser, categories, (list)|(create)
p, advertiser, categories/*, (edit)|(show)|(delete)

p, advertiser, features, (list)|(create)
p, advertiser, features/*, (edit)|(show)|(delete)

p, advertiser, products, (list)|(create)
p, advertiser, products/*, (edit)|(show)|(delete)

p, advertiser, reviews, (list)|(create)
p, advertiser, reviews/*, (edit)|(show)|(delete)

p, advertiser, content-units, (list)|(create)
p, advertiser, content-units/*, (edit)|(show)|(delete)

p, advertiser, profiles, (list)
p, advertiser, profiles/*, (edit)|(show)

g, employee, advertiser, *
g, admin, employee, *
`);
