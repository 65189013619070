import {IResourceComponentsProps, useMany, useShow} from "@pankod/refine-core";
import {Show, Typography, Tag, Card, TextField, Space, ImageField} from "@pankod/refine-antd";
import * as R from 'ramda';
import {ICategory, IProduct} from "types";
import {FC} from "react";

const { Title, Text } = Typography;

export const ProductShow: FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow<IProduct>();
  const { data, isLoading } = queryResult;
  const record = data?.data;
  const features = data?.data.features;
  const categories = data?.data.categories ?? [];
  const brand = data?.data.brand;
  const trackingTemplate = data?.data.trackingTemplate;

  const { data: categoriesData, isLoading: isLoadingCategories } = useMany<ICategory>({
    resource: "categories",
    ids: categories,
    queryOptions: { enabled: categories.length > 0 },
  });

  return (
    <Show isLoading={isLoading}>
      <Title level={5}>Name</Title>
      <Text>{record?.name}</Text>

      <Title level={5}>MPN</Title>
      <Text>
        <Tag>{record?.mpn}</Tag>
      </Text>

      <Title level={5}>SKU</Title>
      <Text>
        <Tag>{record?.sku}</Tag>
      </Text>
      <Title level={5}>Track Offline Conversion</Title>
      <Text>
        <Tag>{String(record?.track_offline_conversion)}</Tag>
      </Text>
      <Title level={5}>CPC</Title>
      <Text>
        <Tag>{record?.cpc}</Tag>
      </Text>
      <Title level={5}>Rating</Title>
      <Text>
        <Tag>{record?.rating}</Tag>
      </Text>
      <Title level={5}>Phone Number</Title>
      <Text>
        <Tag>{record?.phone}</Tag>
      </Text>

      <Title level={5}>Categories</Title>
      <Text>
        {
          isLoadingCategories ?
          <TextField value="Loading..." /> :
          categories.map((id: string) => categoriesData?.data
            .find(R.propEq('id', id))?.name)
            .map((s, index) => <Tag key={index}>{s}</Tag>)
        }
      </Text>

      <Title level={5}>Brand</Title>
      <Text>
        {
          <Tag>{brand?.name}</Tag>
        }
      </Text>
  
      <Title level={5}>Tracking Template</Title>
      <Text>
        {trackingTemplate ? <Tag>{trackingTemplate?.name}: {trackingTemplate?.placeholder}</Tag> : null}
      </Text>
  
      <Title level={5}>Features</Title>
      <Text>
      {
          features?.map(feature => {
              return (
                  <Card style={{ marginTop: "16px" }} size="small" type="inner" title={feature.label}>
                    <Text>
                        {feature.value}
                    </Text>
                  </Card>
              )
          })
      }
      </Text>

      <Title level={5}>Carousel images</Title>
      <Space wrap>
        {record?.carouselImages?.map((image) => (
          <ImageField
            key={image.name}
            value={image.url}
            title={image.name}
            width={200}
          />
        ))}
      </Space>
      <Title level={5}>Screenshots</Title>
      <Space wrap>
        {record?.screenshots?.map((image) => (
          <ImageField
            key={image.name}
            value={image.url}
            title={image.name}
            width={200}
          />
        ))}
      </Space>
  
      <Title level={5}>Final URL</Title>
      <Text>
        {record?.finalUrl}
      </Text>
      <Title level={5}>Heading</Title>
      <Text>
        {record?.heading}
      </Text>
      <Title level={5}>Short description</Title>
      <Text>
        {record?.shortDescription}
      </Text>
      <Title level={5}>Description</Title>
      <Text>
        {record?.description}
      </Text>
      <Title level={5}>Pros</Title>
      <Text>
        {record?.pros.join(', ')}
      </Text>
      <Title level={5}>Features</Title>
      <Text>
        {record?.features.map(feature => (
          <div key={feature.id}>
            {feature.label}: {feature.value}
          </div>
        ))}
      </Text>

    </Show>
  );
};
