import { FC, useEffect } from 'react';
import { IResourceComponentsProps, useApiUrl, useShow } from "@pankod/refine-core";
import {
  Edit,
  Form,
  getValueFromEvent,
  Input,
  message,
  Select,
  Switch,
  Upload,
  UploadFile,
  useForm
} from "@pankod/refine-antd";
import "react-mde/lib/styles/css/react-mde-all.css";
import { ICategory } from "types";
import axios from "axios";
import { MdeFormItem } from "../../components";

export const CategoryEdit: FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm<ICategory>();
  const apiUrl = useApiUrl();
  const { queryResult } = useShow<ICategory>();
  const { data, isLoading } = queryResult;
  const record = data?.data;
  const { form } = formProps;

  useEffect(() => {
    if (record?.configuration) {
      form?.setFieldsValue({
        configuration: JSON.stringify(record?.configuration, null, 2)
      });
    }
  }, [record]);

  const handleImageRemove = async (file: any) => {
    try {
      const response = await fetch(`${apiUrl}/categories/${record?.id}/categoryImage`, {
        method: 'DELETE'
      });
      if (!response.ok) {
        throw new Error('Network response was not ok.');
      }
      message.success('Image removed successfully');
    } catch (error) {
      message.error('Error removing image');
      console.error('Error removing image:', error);
    }
  };

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">

        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Alias"
          name="alias"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item label="Title" name="title" rules={[ { required: true } ]}>
          <Input />
        </Form.Item>

        <MdeFormItem label="Description" name="description" rules={[ { required: true } ]} />

        <Form.Item label="Hero image">
          <Form.Item
            name="heroImage"
            valuePropName="fileList"
            getValueFromEvent={getValueFromEvent}
            noStyle
          >
            <Upload.Dragger
              name="file"
              action={`${apiUrl}/upload?type=hero-images`}
              headers={{ 'Authorization': axios.defaults.headers.common.Authorization.toString() }}
              listType="picture"
              multiple={false}
              maxCount={1}
            >
              <p className="ant-upload-text">
                Drag & drop a file in this area
              </p>
            </Upload.Dragger>
          </Form.Item>
        </Form.Item>
        {/* hero_enabled & categories_enabled for home page rendering */}
        <Form.Item label="Hero Feature Icon" name="icon">
          <Input />
        </Form.Item>
        <Form.Item label="Feature Gallery Image">
          <Form.Item
            name="categoryImage"
            valuePropName="fileList"
            getValueFromEvent={getValueFromEvent}
            noStyle
          >
            <Upload.Dragger
              name="file"
              action={`${apiUrl}/upload?type=category-images`}
              headers={{ 'Authorization': axios.defaults.headers.common.Authorization.toString() }}
              listType="picture"
              multiple={false}
              maxCount={1}
              onRemove={handleImageRemove}
            >
              <p className="ant-upload-text">
                Drag & drop a file in this area
              </p>
            </Upload.Dragger>
          </Form.Item>
        </Form.Item>
        {/* fullpage widget */}
        <Form.Item label="Configuration" name="configuration">
          <Input.TextArea/>
        </Form.Item>
        <Form.Item label="Widget Enabled" name="widget_enabled">
          <Switch defaultChecked={record?.widget_enabled === true}/>
        </Form.Item>
        <Form.Item label="Widget Type" name="widget_type">
          <Select>
            <Select.Option value="custom_widget">Custom Widget</Select.Option>
            <Select.Option value="mediaalpha">MediaAlpha</Select.Option>
            <Select.Option value="quinstreet">QuinStreet</Select.Option>
            <Select.Option value="transparently">Transparently</Select.Option>
            <Select.Option value="myfinance">MyFinance/BankRate</Select.Option>
            <Select.Option value="best_colleges">Best Colleges</Select.Option>
          </Select>
        </Form.Item>
        {/*  */}
      </Form>
    </Edit>
  );
};
