import { FC } from 'react';
import {IResourceComponentsProps, useApiUrl, useNavigation} from "@pankod/refine-core";
import {
  Edit,
  Form,
  Input,
  getValueFromEvent,
  Upload,
  useForm,
} from "@pankod/refine-antd";
import "react-mde/lib/styles/css/react-mde-all.css";

import {IProfile} from "types";
import axios from "axios";
import {CognitoUser} from "@aws-amplify/auth";
import {CognitoUserSession} from "amazon-cognito-identity-js";
import {Auth} from "aws-amplify";

const refreshSession = async (): Promise<CognitoUserSession> => {
  const user: CognitoUser = await Auth.currentAuthenticatedUser();
  const session: CognitoUserSession = await Auth.currentSession();
  return new Promise((resolve) => {
    user.refreshSession(session.getRefreshToken(), () => resolve(Auth.currentSession()));
  });
};

export const ProfileEdit: FC<IResourceComponentsProps> = () => {
  const { list } = useNavigation();
  const onMutationSuccess = () => refreshSession().then(response => {
    axios.defaults.headers.common = { Authorization: `Bearer ${response.getIdToken().getJwtToken()}` };
    list('profiles')
  });
  const { formProps, saveButtonProps } = useForm<IProfile>({ redirect: false, onMutationSuccess });
  const apiUrl = useApiUrl();

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              min: 2,
              max: 255,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Role"
          name="role"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          label="Bio"
          name="bio"
          rules={[
            {
              required: true,
              min: 2,
              max: 2048,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Social 1"
          name="social1"
          rules={[
            {
              required: false,
              min: 2,
              max: 255,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Social 2"
          name="social2"
          rules={[
            {
              required: false,
              min: 2,
              max: 255,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Social 3"
          name="social3"
          rules={[
            {
              required: false,
              min: 2,
              max: 255,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Profile">
          <Form.Item
            name="profile"
            valuePropName="fileList"
            getValueFromEvent={getValueFromEvent}
            noStyle
          >
            <Upload.Dragger
              name="file"
              action={`${apiUrl}/upload?type=profiles&size=150`}
              headers={{ 'Authorization': axios.defaults.headers.common.Authorization.toString() }}
              listType="picture"
              multiple={false}
              maxCount={1}
            >
              <p className="ant-upload-text">
                Drag & drop a file in this area
              </p>
            </Upload.Dragger>
          </Form.Item>
        </Form.Item>
      </Form>
    </Edit>
  );
};
