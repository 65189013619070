import { FC } from "react";
import { IResourceComponentsProps } from "@pankod/refine-core";
import {
  List,
  Table,
  TextField,
  useTable,
  getDefaultSortOrder,
  DateField,
  Space,
  EditButton,
  DeleteButton,
  ShowButton,
  ImageField,
} from "@pankod/refine-antd";
import * as R from 'ramda';
import {IImage, IContentUnit} from "../../types";

export const ContentUnitList: FC<IResourceComponentsProps> = () => {
  const { tableProps, sorter } = useTable<IContentUnit>({
    initialSorter: [
      {
        field: "createdAt",
        order: "desc",
      },
    ],
  });

  return (
    <List>
      <Table {...tableProps} rowKey="id">
        <Table.Column
          dataIndex="slug"
          key="slug"
          title="Slug"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("slug", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="title"
          key="title"
          title="Title"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("title", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="content"
          key="content"
          title="Content"
          render={(value) => <TextField value={value ? `${ value.substring(0, 50)}...` : value } />}
        />
        <Table.Column
          dataIndex="breadcrumb"
          key="breadcrumb"
          title="Breadcrumb"
          render={(value) => <TextField value={value ?? '-'} />}
          defaultSortOrder={getDefaultSortOrder("breadcrumb", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="locale"
          key="locale"
          title="Locale"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("locale", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="status"
          key="status"
          title="Status"
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="createdAt"
          key="createdAt"
          title="Created At"
          render={(value) => <DateField value={value} format="LLL" />}
          defaultSortOrder={getDefaultSortOrder("createdAt", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="publishedAt"
          key="publishedAt"
          title="Published At"
          render={(value) => value ? <DateField value={value} format="LLL" /> : '-'}
          defaultSortOrder={getDefaultSortOrder("publishedAt", sorter)}
          sorter
        />
        <Table.Column
          dataIndex={["category"]}
          title="Category"
          render={(value) => {
            return (
              <TextField value={value?.name} />
            );
          }}
        />
        <Table.Column
          dataIndex={["images"]}
          title="Image"
          render={(value: IImage[]) => {
            const image: IImage | undefined = R.head(value || []);
            return image?.url ? (
              <ImageField key={image?.name} value={image.url} title={image?.name} width={70} />
            ) : null;
          }}
        />
        <Table.Column<IContentUnit>
          title="Actions"
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <ShowButton hideText size="small" recordItemId={record.id} />
              <DeleteButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
