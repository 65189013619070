import {FC, useEffect} from 'react';
import * as R from "ramda";
import {FormInstance} from "antd";
import { IResourceComponentsProps } from "@pankod/refine-core";
import {
  Card,
  Edit,
  Form,
  Input,
  Select,
  useForm,
  useSelect
} from "@pankod/refine-antd";
import {useForm as useReactHookForm} from "@pankod/refine-react-hook-form";
import "react-mde/lib/styles/css/react-mde-all.css";

import {ICategory, IFeature} from "types";
import {LOCALES} from "../../config";

export const FeatureEdit: FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult, form, onFinish, formLoading } = useForm<IFeature>();
  const categories = queryResult?.data?.data?.categories ?? [];
  useEffect(() => { if (!formLoading) syncCategories(categories, form); }, [formLoading]);
  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} onFinish={onSubmit(onFinish)} layout="vertical">
        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Label"
          name="label"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Locale"
          name="locale"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            placeholder='Select Locale'
            options={LOCALES.map(locale => ({label: locale, value: locale}))}
          />
        </Form.Item>
        <Categories form={form} />
      </Form>
    </Edit>
  );
};

export const Categories = ({form}: {form: FormInstance}) => {
  const { register } = useReactHookForm();
  const { selectProps: categorySelectProps } = useSelect<ICategory>({
    resource: 'categories',
    optionLabel: 'name',
    pagination: { pageSize: Number.MAX_SAFE_INTEGER },
  });
  return (
    <Form.Item
      shouldUpdate={(prevValues, currentValues) => prevValues.categories !== currentValues.categories}
    >
      {
        ({getFieldValue}) => (
          <>
            <button onClick={(e) => {
              e.preventDefault();
              const categories = getFieldValue('categories') ?? [];
              form.setFieldsValue({categories: [...categories, {}]});
            }}>
              Add category
            </button>
            {
              form.getFieldValue('categories')?.map((_: any, index: number) => {
                return (
                  <Card key={index} style={{ marginTop: "16px" }} size="small" type="inner">
                    <Form.Item
                      {...register(`categories.${index}.id`)}
                      label="Category"
                      name={`categories.${index}.id`}
                      rules={[ { required: true, }, ]}
                    >
                      <Select
                        placeholder='Select Category'
                        {...categorySelectProps}
                      />
                    </Form.Item>

                    <Form.Item
                      {...register(`categories.${index}.required`)}
                      label="Required"
                      name={`categories.${index}.required`}
                      rules={[ { required: true, }, ]}
                    >
                      <Select
                        placeholder='Required'
                        options={
                          [true, false].map(status => ({label: `${status}`, value: status}))
                        }
                      />
                    </Form.Item>

                    <button onClick={(e) => {
                      e.preventDefault();
                      const {allCategoryKeys, categories} = toCategories(form.getFieldsValue());
                      form.resetFields(allCategoryKeys);
                      const updatedCategories = R.remove(index, 1, categories);
                      syncCategories(updatedCategories, form);
                      form.setFieldsValue({categories: updatedCategories});
                    }}>
                      Remove
                    </button>
                  </Card>
                )
              })
            }
          </>
        )
      }
    </Form.Item>
  );
};

export const onSubmit = (onFinish: (values: any) => Promise<any>) => async (payload: any) => {
  const {categories, allCategoryKeys} = toCategories(payload);
  await onFinish(R.omit(allCategoryKeys,{...payload, categories}));
};

const toCategories = (payload: any) => {
  const allCategoryKeys = Object.keys(payload).filter(s => s.startsWith('categories.'));
  const categoryKeys = allCategoryKeys.filter(s => /^categories\.[0-9]+\.(:?id|required)/g.test(s)).sort();
  const categories = categoryKeys.reduce((result: { id: string, required?: boolean }[], current: string) => {
    const value: unknown = payload[current];
    if (value === undefined) return result;
    if (current.endsWith('id')) result.push({ id: value as string });
    else result[result.length - 1].required = value as boolean;
    return result;
  }, [] as { id: string, required: boolean }[]);
  return {categories, allCategoryKeys};
};


const syncCategories = (categories: any[], form: FormInstance) => {
  const result: any = {};
  for (let i = 0; i < categories.length; i = i + 1) {
    const category = categories[i];
    result[`categories.${i}.id`] = category.id;
    result[`categories.${i}.required`] = category.required;
  }
  form.setFieldsValue(result);
};
