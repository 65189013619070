import {IResourceComponentsProps, useShow} from "@pankod/refine-core";
import {Show, Typography, Tag, Space, ImageField} from "@pankod/refine-antd";
import {IContentUnit} from "../../types";
import {FC} from "react";

const { Title, Text } = Typography;

export const ContentUnitShow: FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow<IContentUnit>();
  const { data, isLoading } = queryResult;
  const record = data?.data;
  const category = data?.data.category;

  return (
    <Show isLoading={isLoading}>
      <Title level={5}>Slug</Title>
      <Text>{record?.slug}</Text>

      <Title level={5}>Title</Title>
      <Text>{record?.title}</Text>

      <Title level={5}>Content</Title>
      <Text>{record?.content}</Text>

      <Title level={5}>Breadcrumb</Title>
      <Text>{record?.breadcrumb}</Text>

      <Title level={5}>Locale</Title>
      <Text>
        <Tag>{record?.locale}</Tag>
      </Text>

      <Title level={5}>Status</Title>
      <Text>
        <Tag>{record?.status}</Tag>
      </Text>

      <Title level={5}>Category</Title>
      <Text>
        {
          <Tag>{category?.name}</Tag>
        }
      </Text>

      <Title level={5}>Images</Title>
      <Space wrap>
        {record?.images?.map((image) => (
          <ImageField
            key={image.name}
            value={image.url}
            title={image.name}
            width={200}
          />
        ))}
      </Space>
    </Show>
  );
};
