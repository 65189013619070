import {
  TOP6_CMS_USER_POOL_REGION,
  TOP6_CMS_USER_POOL_ID,
  TOP6_CMS_USER_POOL_OAUTH_DOMAIN,
  TOP6_CMS_USER_POOL_WEB_CLIENT_ID,
  TOP6_CMS_APP_ENDPOINT
} from "./constants";
import {Amplify} from "aws-amplify";
import {AuthProvider} from "@pankod/refine-core";
import { CognitoUser } from '@aws-amplify/auth'

// References: https://ui.docs.amplify.aws/getting-started/installation?platform=react
export const initAuth = () => {
  Amplify.configure({
    Auth: {
      region: TOP6_CMS_USER_POOL_REGION,
      userPoolId: TOP6_CMS_USER_POOL_ID,
      userPoolWebClientId: TOP6_CMS_USER_POOL_WEB_CLIENT_ID,
      oauth: {
        domain: TOP6_CMS_USER_POOL_OAUTH_DOMAIN,
        scope: ['phone', 'email', 'openid', 'profile' /* "aws.cognito.signin.user.admin" */],
        redirectSignIn: `${TOP6_CMS_APP_ENDPOINT}`, // /callback
        redirectSignOut: `${TOP6_CMS_APP_ENDPOINT}`, // /sign-out
        responseType: 'code',
      },
      federationTarget: 'COGNITO_USER_POOLS',
    },
    authenticationFlowType: 'USER_SRP_AUTH',
  });
};

type Claim = {
  username: string;
  email: string;
  role: string;
};

export const extractClaim = (user: CognitoUser): Claim => {
  const payload = user?.getSignInUserSession()?.getIdToken()?.payload;
  return {
    username: payload?.['cognito:username'],
    email: payload?.email,
    role: payload?.['custom:role'],
  };
}

type Props = {
  signOut: (data?: Record<string | number | symbol, any>) => void;
  authenticated: boolean;
  user: CognitoUser;
};
export const createAuthProvider = ({signOut, authenticated, user}: Props): AuthProvider => ({
  login: () => {
    return Promise.resolve();
  },
  logout: () => {
    signOut();
    return Promise.resolve("/");
  },
  checkError: () => Promise.resolve(),
  checkAuth: () => {
    if (authenticated) {
      return Promise.resolve();
    }

    return Promise.reject();
  },
  getPermissions: () => {
    const allowedRoles = ['admin', 'employee', 'advertiser'];
    const { role } = extractClaim(user);
    return allowedRoles.includes(role) ? Promise.resolve(role) : Promise.reject();
  },
  getUserIdentity: async () => {
    if (user) {
      const { username, email, role } = extractClaim(user);
      return Promise.resolve({ username, email, role });
    }
  },
});
